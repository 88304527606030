import {TextField} from "@mui/material";
import {useState} from "react";

export const DateBox = ({callback, label = ""}) => {
    const [selectedDate, setSelectedDate] = useState('');

    const handleDateChange = (event) => {
        const date = event.target.value;
        setSelectedDate(date);
        callback(date);
    };

    return (
        <TextField
            type="date"
            label={label}
            value={selectedDate}
            onChange={handleDateChange}
            InputLabelProps={{
                shrink: true,
            }}
        />
    );
};