import {TextField} from "@mui/material";

export const TextBox = ({title, value, onChange, helperText}) => {
    return <TextField
        sx={{margin: "10px 0"}}
        fullWidth
        label={title}
        value={value}
        helperText={helperText}
        onChange={(event) => onChange(event.target.value)}
    />
}