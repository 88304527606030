import {createContext, useContext, useEffect, useState} from "react";
import {useAuth} from "./AuthContext";

const OutputContext = createContext({});

export const OutputContextProvider = ({children}) => {
    const auth = useAuth();
    const [loading, setLoading] = useState(false);
    const [Component, setComponent] = useState();
    const [path, setPath] = useState();

    // Reset when login status changes
    useEffect(() => {
        setLoading(false);
        setComponent(null);
        setPath(null);
    }, [auth.loggedIn])

    return <OutputContext.Provider value={{
        loading,
        setLoading,
        path,
        setPath,
        Component,
        setComponent
    }}>
        {children}
    </OutputContext.Provider>
}

export const useOutput = () => {
    return useContext(OutputContext);
}