import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import React from "react";

export const DropDown = ({ title, options, value, onChange, ...props }) => {
    return <FormControl
        sx={{margin: "10px 0"}}
        fullWidth
        {...props}
    >
        <InputLabel>{title}</InputLabel>
        <Select
            value={value}
            label={title}
            onChange={(event) => onChange(event.target.value)}
        >
            {options.map((option, index) => {
                return <MenuItem
                    key={"dropdown-" + title + "-" + option }
                    value={index}
                >
                    {option}
                </MenuItem>
            })}
        </Select>
    </FormControl>
};