class BannerService{
    password;
    apiUrl;

    constructor(password, apiUrl){
        this.password = password;
        this.apiUrl = apiUrl;
    }

    async getSignedUrl(){
        // Throws error if not successful

        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": this.password
            }
        };

        const response = await fetch(
            this.apiUrl + "/api/Banners/signed-url",
            requestOptions
        );

        if(!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.text();
    }

    async uploadImage(uploadLink, file) {
        // Throws error if not successful

        const requestOptions = {
            method: "PUT",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            body: file
        };

        const response = await fetch(
            uploadLink,
            requestOptions
        );

        if(!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return uploadLink.split("?")[0];
    }
}

export default BannerService;