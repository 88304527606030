import React, {forwardRef, Fragment, useImperativeHandle, useState} from "react";
import {Json} from "../../../../basic/Json";

export const BasicStats = forwardRef((props, ref) => {
    const [id, setId] = useState('');

    useImperativeHandle(ref, () => {
        return {
            getPathname: () => "/dashboard/stats/basic",
            getParameters: () => [],
            createOutput: createOutput,
            handleGo: () => {}
        }
    })

    const createOutput = (json) => {
        return <Json json={json}/>
    }

    return <Fragment>
    </Fragment>
});