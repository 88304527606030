import {Box} from "@mui/material";

export const Space = ({width, height, sx, ...props}) => {
    return <Box
        sx={{
            width: width,
            height: height,
            minWidth: width,
            minHeight: height,
            ...sx
        }}
        {...props}
    />
}