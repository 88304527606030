import {Line} from "react-chartjs-2";
import {useTheme} from "@mui/material";

export const LineChart = ({data}) => {
    const theme = useTheme();
    const labels = Array.from({ length: data.length }, (_, i) => i + 1);

    const chartData = {
        labels: labels,
        datasets: [{
            data: data,
            borderColor: theme.palette.primary.main,
        }]
    };

    const options = {
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Day'
                }
            },
            y: {
                title: {
                    display: true,
                    text: 'Users'
                }
            }
        }
    };

    return <Line data={chartData} options={options} />;
}