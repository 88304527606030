import {Box, Typography} from "@mui/material";
import {Space} from "./basic/Space";
import {Row} from "./basic/Row";
import {useAuth} from "../contexts/AuthContext";
import {Column} from "./basic/Column";
import {Divider} from "./basic/Divider";

export const Header = ({ children }) => {
    const auth = useAuth();

    return <Column sx={{
        height: `calc(100vh - 16px)`,  // To account for the 8px margin on the body
    }}>
        {/* Header */}
        <Row sx={{
            justifyContent: "space-between",
            alignItems: "center",
        }}>
            {/* Logo left */}
            <Row sx={{
                width: "100%",
                alignItems: "center",
            }}>
                <img
                    src="/assets/jagtbasen-logo-bird-no-text.png"
                    style={{width: "75px", paddingBottom: "10px"}}
                />
                <Space width="10px"/>
                <Typography variant="h5">Dashboard</Typography>
            </Row>

            {/* Backend name right */}
            {auth.api && (
                <Column sx={{
                    alignItems: "flex-end",
                }}>
                    <Typography sx={{
                        lineHeight: "10px"
                    }}>
                        Backend
                    </Typography>
                    <Typography sx={{
                        fontWeight: "bold",
                    }}>
                        {auth.api?.name}
                    </Typography>
                </Column>
            )}
        </Row>

        <Divider/>

        {/* Body */}
        <Box sx={{flexGrow: 1, overflow: "auto"}}>
            {children}
        </Box>
    </Column>
}