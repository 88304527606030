import React, {forwardRef, Fragment, useImperativeHandle, useState} from "react";
import {Json} from "../../../../basic/Json";
import {TextBox} from "../../../../form/TextBox";
import {Title} from "../../../../form/Title";

export const Post = forwardRef((props, ref) => {
    const [id, setId] = useState('');

    useImperativeHandle(ref, () => {
        return {
            getPathname: () => "/posts/dash",
            getParameters: getParameters,
            createOutput: createOutput,
            handleGo: () => {}
        }
    })

    const getParameters = () => {
        return [
            {key: "id", value: id},
        ];
    }

    const createOutput = (json) => {
        return <Json json={json}/>
    }

    const handleIdChange = (value) => {
        setId(value);
    }

    return <Fragment>
        <Title>Parameter</Title>
        <TextBox title="Post ID" value={id} onChange={handleIdChange}/>
    </Fragment>
});