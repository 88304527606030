import React, {FC, ReactNode} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

// Inspiration: https://dev.to/metamodal/control-a-dialog-box-asynchronously-using-react-hooks-4ik7

/*
  HOW TO USE:
  const getConfirmation = useConfirmationDialog()

  const confirmed = await getConfirmation(
    "Title",
    "Message",
    <div/>
  );

  if (confirmed) ...
*/

const ConfirmationDialog: FC<{
    open: boolean;
    onConfirm: () => void;
    onDismiss: () => void;
    title?: string;
    message?: string;
    node?: ReactNode;
    cancelText?: string;
    confirmText?: string;
}> = ({ open, onConfirm, onDismiss, title, message, node, cancelText, confirmText }) => {
    return (
        <Dialog open={open} onClose={onDismiss}>
            {title != null && <DialogTitle>{title}</DialogTitle>}
            {message != null && (
                <DialogContent>
                    <DialogContentText>{message}</DialogContentText>
                </DialogContent>
            )}
            {node != null && (
                <DialogContent sx={{paddingTop: 0}}>
                    {node}
                </DialogContent>
            )}
            <DialogActions>
                <Button onClick={onDismiss}>
                    {cancelText ?? "Cancel"}
                </Button>
                <Button color="primary" variant="contained" onClick={onConfirm}>
                    {confirmText ?? "Confirm"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

type dialogState = {
    open: boolean;
    title?: string;
    message?: string;
    node?: ReactNode;
    callback?: (value: boolean) => void;
    cancelText?: string;
    confirmText?: string;
};

export type openDialogFunction = (
    callback: (value: boolean) => void,
    title?: string,
    message?: string,
    node?: ReactNode,
    cancelText?: string,  // Name of the cancel button
    confirmText?: string  // Name of the confirm button
) => void;

const ConfirmationDialogContext = React.createContext<openDialogFunction>(
    {} as openDialogFunction
);

export const ConfirmationDialogProvider: FC = ({children}) => {
    const [dialogConfig, setDialogConfig] = React.useState<dialogState>({
        open: false,
    });

    const openDialog: openDialogFunction = (callback, title, message, node, cancelText,  confirmText) => {
        setDialogConfig({
            open: true,
            callback: callback,
            title: title,
            message: message,
            node: node,
            cancelText: cancelText,
            confirmText: confirmText
        });
    };

    const resetDialog = () => {
        setDialogConfig({ ...dialogConfig, open: false });
    };

    const onConfirm = () => {
        resetDialog();
        dialogConfig.callback!(true);
    };

    const onDismiss = () => {
        resetDialog();
        dialogConfig.callback!(false);
    };

    return (
        <ConfirmationDialogContext.Provider value={openDialog}>
            <ConfirmationDialog
                open={dialogConfig.open}
                title={dialogConfig.title!}
                message={dialogConfig.message!}
                node={dialogConfig.node}
                cancelText={dialogConfig.cancelText}
                confirmText={dialogConfig.confirmText}
                onConfirm={onConfirm}
                onDismiss={onDismiss}
            />
            {children}
        </ConfirmationDialogContext.Provider>
    );
};

export const useConfirmationDialog = () => {
    const openDialog = React.useContext(ConfirmationDialogContext);

    type ConfirmationOptions = {
        title?: string;
        message?: string;
        node?: ReactNode;
        cancelText?: string;
        confirmText?: string;
    };

    const getConfirmation = ({title, message, node, cancelText, confirmText}: ConfirmationOptions) =>
    new Promise((res) => {
        openDialog(res, title, message, node, cancelText, confirmText);
    });

    return getConfirmation;
};
