import React, {forwardRef, Fragment, useImperativeHandle, useState} from "react";
import {Typography} from "@mui/material";

export const HealthPing = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => {
        return {
            getPathname: () => "/HealthCheck/ping",
            getParameters: () => [],
            createOutput: createOutput,
            handleGo: () => {}
        }
    })

    const createOutput = (msg) => {
        return <Typography>Version: {msg}</Typography>
    }

    return <Fragment>
    </Fragment>
});