import React, {forwardRef, Fragment, useImperativeHandle, useState} from "react";
import {Title} from "../../../../form/Title";
import InputFile from "../../../../form/InputImage";
import {Text} from "../../../../form/Text";
import useSnackbar from "../../../../hooks/Snackbar";
import {useOutput} from "../../../../../contexts/OutputContext";
import BannerService from "../../../../../services/BannerService";
import {useAuth} from "../../../../../contexts/AuthContext";

export const UploadImage = forwardRef((props, ref) => {
    const snackbar = useSnackbar();
    const output = useOutput();
    const auth = useAuth();

    const bannerSerivce = new BannerService(auth.password, auth.api.url);

    const [image, setImage] = useState();

    useImperativeHandle(ref, () => {
        return {
            handleGo: handleGo
        }
    })

    const handleGo = async () => {
        output.setLoading(true);
        output.setComponent(null);
        output.setPath("-");

        try {
            // Check if an image is not selected
            if (!image) {
                snackbar.open("Choose an image to upload", "error");
                return false;
            }

            // Get secure URL for S3 upload
            let uploadLink;
            try {
                uploadLink = await bannerSerivce.getSignedUrl();
            } catch (e) {
                snackbar.open("Could not get signed URL", "error");
                return false;
            }

            // Upload image to S3
            let downloadLink;
            try {
                downloadLink = await bannerSerivce.uploadImage(uploadLink, image);
            } catch(e) {
                snackbar.open("Could not upload image", "error");
                return false;
            }

            output.setComponent(<Fragment>
                <Title>Link:</Title>
                <Text>{downloadLink}</Text>
            </Fragment>)
            return false;

        } finally {
            setImage(null);
            output.setLoading(false);
        }
    }

    const handleFileInput = (file) => {
        setImage(file);
    }

    return <Fragment>
        <Text>
            Make sure to respect the aspect ratios
            {<br/>}
            D1: 4:1 {<br/>}
            M1: 2:1 {<br/>}
            D2: 1:3 {<br/>}
            M2: 5:2 {<br/>}
            D3: 4:3 {<br/>}
            M3: 3:1 {<br/>}
        </Text>
        <InputFile callback={handleFileInput}/>
        {image && <Text>{image["name"]}</Text>}
    </Fragment>
});