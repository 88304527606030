import {apis, useAuth} from "../contexts/AuthContext";
import {Box, Button, IconButton, InputAdornment, TextField, Typography} from "@mui/material";
import {Space} from "./basic/Space";
import {DropDown} from "./form/DropDown";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import {Center} from "./basic/Center";
import React, {useEffect, useState} from "react";
import snackbar from "./hooks/Snackbar";

export const Auth = ({children}) => {
    const width = "350px";

    const auth = useAuth();
    const [backend, setBackend] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const handleBackendPick = (value) => {
        setBackend(value);
    };

    const handleNewPassword = (event) => {
        setPassword(event.target.value);
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleLoginAttempt = () => {
        if (backend === '') {
            snackbar.open("Please select a backend", "error");
            return;
        }

        auth.login(backend, password);
    }

    // Reset when login status changes
    useEffect(() => {
        setBackend('');
        setPassword('');
        setShowPassword(false)
    }, [auth.loggedIn])

    if (auth.loggedIn) {
        return <>{children}</>
    }

    return <Center>
        {/* Title */}
        <Typography variant="h5">Login</Typography>
        <Space height="20px"/>

        {/* Backend picker */}
        <DropDown
            title="Backend"
            value={backend}
            onChange={handleBackendPick}
            options = {apis.map(api => api.name)}
            sx={{maxWidth: width}}
        />
        <Space height="10px"/>

        {/* Password field */}
        <TextField
            fullWidth
            value={password}
            variant="outlined"
            style={{ maxWidth: width }}
            type={showPassword ? 'text' : 'password'}
            label="Password"
            onChange={handleNewPassword}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={handleClickShowPassword}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
        <Space height="10px"/>

        {/* Login button */}
        <Box sx={{width: width, textAlign: "end"}}>
            <Button
                variant="contained"
                onClick={handleLoginAttempt}
            >Login</Button>
        </Box>

        <Space height="35vh"/>
    </Center>
}