import {Box, useTheme} from "@mui/material";

export const Divider = ({children, vertical = false}) => {
    const theme = useTheme();

    return <Box sx={{
        height: vertical ? "100%" : "1px",
        width: vertical ? "1px" : "100%",
        backgroundColor: theme.palette.grey[300],
    }}>
        {children}
    </Box>
}
