import {Bar, Doughnut, Line} from "react-chartjs-2";

export const DeleteReasonCounts = ({ data }) => {
    const chartData = {
        labels: data.deleteReasonCounts.map(item => item.deleteReason),
        datasets: [
            {
                label: 'Count of Deleted Posts by Reason',
                data: data.deleteReasonCounts.map(item => item.count),
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)'
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)'
                ],
                borderWidth: 1
            }
        ]
    };

    const chartOptions = {
        scales: {
            y: {
                beginAtZero: true
            }
        },
        legend: {
            display: true
        },
        title: {
            display: true,
            text: 'Count of Deleted Posts by Reason'
        }
    };

    return <Bar data={chartData} options={chartOptions} />;
};

export const DailyDeletionCounts = ({ data }) => {
    const chartData = {
        labels: data.dailyDeletionCounts.map(item => item.deleteDate),
        datasets: [
            {
                label: 'Daily Deletion Counts',
                data: data.dailyDeletionCounts.map(item => item.dailyDeletionCount),
                fill: false,
                borderColor: 'rgb(75, 192, 192)',
                tension: 0.1
            }
        ]
    };

    const chartOptions = {
        scales: {
            y: {
                beginAtZero: true
            }
        },
        legend: {
            display: true
        },
        title: {
            display: true,
            text: 'Daily Deletion Trends'
        }
    };

    return <Line data={chartData} options={chartOptions} />;
};

export const PostDeletionRatioToTotalPosts = ({ data }) => {
    const { deletedPostsCount, totalPostsCount } = data.deletionRationToTotalPosts;
    const remainingPostsCount = totalPostsCount - deletedPostsCount;

    const chartData = {
        labels: ['Deleted Posts', 'Remaining Posts'],
        datasets: [
            {
                label: 'Post Deletion Ratio to Total Posts',
                data: [deletedPostsCount, remainingPostsCount],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)'
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)'
                ],
                borderWidth: 1
            }
        ]
    };

    return <Doughnut data={chartData} />;
};

export const  DeletionTimeAnalysis = ({ data }) => {
    const chartData = {
        labels: data.deletionTimesCalculation.map(item => item.deleteReason),
        datasets: [
            {
                label: 'Average Duration Before Deletion',
                data: data.deletionTimesCalculation.map(item => item.averageDurationBeforeDeletion),
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
                label: 'Minimum Duration Before Deletion',
                data: data.deletionTimesCalculation.map(item => item.minDurationBeforeDeletion),
                backgroundColor: 'rgba(54, 162, 235, 0.5)',
            },
            {
                label: 'Maximum Duration Before Deletion',
                data: data.deletionTimesCalculation.map(item => item.maxDurationBeforeDeletion),
                backgroundColor: 'rgba(255, 206, 86, 0.5)',
            }
        ]
    };

    const chartOptions = {
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Duration (in seconds)'
                }
            }
        },
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Deletion Time Analysis'
            },
        }
    };

    return <Bar data={chartData} options={chartOptions} />;
};