import {Bar} from "react-chartjs-2";
import {Doughnut} from 'react-chartjs-2';
import {Stack, Typography, useTheme} from "@mui/material";

export const BarChart = ({data}) => {

    const totalPosts = data.totalPremiumPosts + data.totalBasicPosts + data.totalStandardPosts + data.totalBudgetPosts;
    const totalVisits = data.totalPremiumVisits + data.totalBasicVisits + data.totalStandardVisits + data.totalBudgetVisits;
    console.log(data);

    const premiumVisitRatio = data.totalPremiumVisits / totalVisits;
    const basicVisitRatio = data.totalBasicVisits / totalVisits;

    const budgetVisitRatio = data.totalBudgetPosts / totalVisits;
    const standardVisitRatio = data.totalStandardVisits / totalVisits;

    const averagePremiumVisitsPerPost = data.totalPremiumVisits / data.totalPremiumPosts;
    const averageBasicVisitsPerPost = data.totalBasicVisits / data.totalBasicPosts;

    const averageStandardVisitsPerPost = data.totalStandardVisits / data.totalStandardPosts;
    const averageBudgetVisitsPerPost = data.totalBudgetVisits / data.totalBudgetPosts;

    const premiumVisitPercentageOfTotal = parseFloat((premiumVisitRatio * 100).toFixed(2));
    const basicVisitPercentageOfTotal = parseFloat((basicVisitRatio * 100).toFixed(2));

    const budgetVisitPercentageOfTotal = parseFloat((budgetVisitRatio * 100).toFixed(2));
    const standardVisitPercentageOfTotal = parseFloat((standardVisitRatio * 100).toFixed(2));

    const theme = useTheme();
    const chartData = {
        labels: ['Antal Annoncer (Premium vs. Basic vs. Budget vs. Standard)'],
        datasets: [
            {
                label: 'Premium Posts',
                data: [data.totalPremiumPosts],
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
            },
            {
                label: 'Standard Posts',
                data: [data.totalStandardPosts],
                backgroundColor: 'rgba(255,236,0,0.5)',
                borderColor: 'rgb(255,236,0)',
                borderWidth: 1,
            },
            {
                label: 'Budget Posts',
                data: [data.totalBudgetPosts],
                backgroundColor: 'rgba(26,255,0,0.5)',
                borderColor: 'rgb(26,255,0)',
                borderWidth: 1,
            },
            {
                label: 'Basic Posts',
                data: [data.totalBasicPosts],
                backgroundColor: 'rgba(54, 162, 235, 0.5)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1,
            }
        ],
    };

    const options = {
        scales: {
            y: {
                id: 'y-posts',
                type: 'linear',
                position: 'left',
            }
        },
    };


    const chartData2 = {
        labels: ['Gennemsnitlige besøg pr. annonce'], // Categories
        datasets: [
            {
                label: 'Premium Post',
                data: [averagePremiumVisitsPerPost], // Data for total posts
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                ],
                borderWidth: 1
            },
            {
                label: 'Standard Post',
                data: [averageStandardVisitsPerPost], // Data for total posts
                backgroundColor: [
                    'rgba(255,236,0,0.5)',
                ],
                borderColor: [
                    'rgb(255,236,0)',
                ],
                borderWidth: 1
            },
            {
                label: 'Budget Post',
                data: [averageBudgetVisitsPerPost], // Data for total posts
                backgroundColor: [
                    'rgba(26,255,0,0.5)',
                ],
                borderColor: [
                    'rgb(26,255,0)',
                ],
                borderWidth: 1
            },
            {
                label: 'Basic Post',
                data: [averageBasicVisitsPerPost], // Data for total posts
                backgroundColor: [
                    'rgba(54, 162, 235, 0.2)'
                ],
                borderColor: [
                    'rgba(54, 162, 235, 1)'
                ],
                borderWidth: 1
            }]
    }
    const options2 = {
        scales: {
            y: {
                ticks: {
                    beginAtZero: true
                }
            }
        }
    }

    const DoughnutChartComponent = () => {
        const data = {
            labels: ['Premium Visits Ratio', 'Standard Visits Ratio', 'Budget Visits Ratio', 'Basic Visits Ratio'],
            datasets: [
                {
                    data: [premiumVisitPercentageOfTotal, standardVisitPercentageOfTotal, budgetVisitPercentageOfTotal, basicVisitPercentageOfTotal],
                    backgroundColor: ['rgba(255,99,132,0.2)', 'rgba(255,236,0,0.5)', 'rgba(26,255,0,0.5)', 'rgba(54,162,235,0.2)'],
                    borderColor: ['rgb(255,99,132)', 'rgb(255,236,0)', 'rgb(26,255,0)', 'rgb(54,162,235)'],
                    borderWidth: 1,
                },
            ],
        };

        return <Doughnut data={data}/>;
    };

    return <Stack spacing={3}>
        <Bar data={chartData} options={options}/>
        <Bar data={chartData2} options={options2}/>
        <DoughnutChartComponent/>
        <Typography sx={{textAlign: 'center'}} color='#656565' variant='body2'>Besøgsforhold</Typography>
    </Stack>
}