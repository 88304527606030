import React, {Fragment, useRef, useState} from "react";
import {Box, Button} from "@mui/material";
import {Space} from "../../basic/Space";
import {Lottery, LotteryWinners} from "./groups/Lotteries";
import {Users} from "./groups/Users";
import {useAuth} from "../../../contexts/AuthContext";
import useSnackbar from "../../hooks/Snackbar";
import {useOutput} from "../../../contexts/OutputContext";
import {Json} from "../../basic/Json";
import {Post} from "./groups/posts/Posts";
import {Events} from "./groups/Events";
import {Feedbacks} from "./groups/Feedbacks";
import {DropDown} from "../../form/DropDown";
import {Title} from "../../form/Title";
import {ReviewPosts} from "./groups/posts/Review";
import {SyncAlgolia} from "./groups/posts/Algolia";
import {BasicStats} from "./groups/statistics/BasicStats";
import {UsersStats} from "./groups/statistics/UsersStats";
import {PostsStats} from "./groups/statistics/PostsStats";
import {MessagesStats} from "./groups/statistics/MessagesStats";
import {HealthPing} from "./groups/Health";
import {VisitsStats} from "./groups/statistics/VisitsStats";
import {UploadImage} from "./groups/banners/UploadImage";
import {ViewSchedule} from "./groups/banners/ViewSchedule";
import {EditSchedule} from "./groups/banners/EditSchedule";
import {DeletedPosts} from "./groups/statistics/DeletedPosts";

export const Input = () => {
    const output = useOutput();
    const auth = useAuth();
    const snackbar = useSnackbar();
    const endpointRef = useRef();

    const [group, setGroup] = useState('');
    const [endpoint, setEndpoint] = useState('');
    const [loading, setLoading] = useState(false);

    // Each group has its own name and endpoints
    // Each endpoint has its own title and component
    const groups = [
        {name: "Health", endpoints: [
                {title: "Ping", component: <HealthPing/>},
        ]},
        {name: "Users", endpoints: [
            {title: "User", component: <Users/>}
        ]},
        {name: "Posts", endpoints: [
            {title: "Post", component: <Post/>},
            {title: "Review Posts", component: <ReviewPosts/>},
            {title: "Synchronize Algolia", component: <SyncAlgolia/>}
        ]},
        // {name: "Lotteries", endpoints: [
        //     {title: "Lottery", component: <Lottery/>},
        //     {title: "Lottery Winners", component: <LotteryWinners/>},
        // ]},
        {name: "Events", endpoints: [
            {title: "Event", component: <Events/>},
        ]},
        {name: "Feedbacks", endpoints: [
            {title: "Feedback", component: <Feedbacks/>},
        ]},
        {name: "Statistics", endpoints: [
            {title: "Basic", component: <BasicStats/>},
            {title: "Users", component: <UsersStats/>},
            {title: "Posts", component: <PostsStats/>},
            {title: "Messages", component: <MessagesStats/>},
            {title: "Visits", component: <VisitsStats/>},
            {title: "Deleted Posts", component: <DeletedPosts/>}
        ]},
        {name: "Banners", endpoints: [
            {title: "Upload Image", component: <UploadImage/>},
            {title: "View Schedule", component: <ViewSchedule/>},
            {title: "Edit Schedule", component: <EditSchedule/>}
        ]}
    ];

    const handleGroupPick = (value) => {
        output.setComponent(null);
        setEndpoint("");
        setGroup(value);
    }

    const handleEndpointPick = (value) => {
        output.setComponent(null);
        setEndpoint(value);
    };

    const handleGo = async () => {
        if (loading) {
            return;
        }

        setLoading(true);

        // First we evaluate the endpoint component
        const proceed = await endpointRef.current.handleGo();

        // If the endpoint component cancels the default GET call
        if (proceed === false) {
            setLoading(false);
            return;
        }

        output.setLoading(true);
        output.setComponent(null);

        let path;
        try {
            const parameters = endpointRef.current.getParameters();
            const pathname = endpointRef.current.getPathname();

            // Join parameters into a string
            const search = parameters
                .filter(item => item.value != undefined && item.value !== "") // Only include parameters with a value
                .map(item => item.key + "=" + item.value) // Map each parameter to a string
                .join("&"); // Join all parameters with an ampersand;

            // Wrap everything up into a path
            path = auth.api.url + "/api" + pathname + "?" + search

            // Make final API call
            const response = await fetch(path, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": auth.password
                }
            });

            let data;

            // First try to interpret the response as JSON
            // If that fails, try to interpret it as text
            try {
                // Clone as responses can only be read once
                data = await response.clone().json();
            } catch (e) {
                try {
                    data = await response.text();
                } catch (e) {
                    console.error(e);
                }
            }

            if (!response.ok) {
                console.log(response);
                snackbar.open("Something went wrong", "error");
                output.setComponent(<Json json={data}/>);
                return;
            }

            const Output = endpointRef.current.createOutput(data);
            output.setComponent(Output);
        } catch (e) {
            console.log(e);
            output.setComponent(<Json json={{error: e.message}}/>);
            snackbar.open("Something went wrong", "error");
        } finally {
            output.setPath(path);
            output.setLoading(false);
            setLoading(false);
        }
    }

    return <Box sx={{minWidth: "350px", maxWidth: "350px"}}>

        {/* Group picker */}
        <Title>Group</Title>
        <DropDown
            title="Group"
            value={group}
            onChange={handleGroupPick}
            options={groups.map(group => group.name)}
        />
        <Space height="20px"/>

        {/* Endpoint picker */}
        {group !== "" && (
            <Fragment>
                <Title>Endpoint</Title>
                <DropDown
                    title="Endpoint"
                    value={endpoint}
                    onChange={handleEndpointPick}
                    options={groups[group]?.endpoints.map(endpoint => endpoint.title)}
                />
                <Space height="20px"/>
            </Fragment>
        )}

        {/* Parameters */}
        {endpoint !== "" && (
            <Fragment>
                {groups[group].endpoints[endpoint] && (
                    React.cloneElement(
                        groups[group]?.endpoints[endpoint]?.component,
                        { ref: endpointRef }
                    )
                )}
                <Box sx={{width: "100%", textAlign: "end"}}>
                    <Button
                        variant="contained"
                        onClick={handleGo}
                    >Go</Button>
                </Box>
            </Fragment>
        )}
    </Box>
}