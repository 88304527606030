import {Box} from "@mui/material";

export const Center = ({children}) => {
    return <div
        style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: "100%",
        }}
    >
        {children}
    </div>
}