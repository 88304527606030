import React, {forwardRef, Fragment, useImperativeHandle, useState} from "react";
import {Json} from "../../../basic/Json";
import {TextBox} from "../../../form/TextBox";
import {DropDown} from "../../../form/DropDown";
import {Title} from "../../../form/Title";

export const Events = forwardRef((props, ref) => {
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [type, setType] = useState('');

    const options=[
        "Payment link generated - UpgradeToPremium",
        "Payment success - UpgradeToPremium",
    ];

    useImperativeHandle(ref, () => {
        return {
            getPathname: () => "/dashboard/events",
            getParameters: getParameters,
            createOutput: createOutput,
            handleGo: () => {}
        }
    })

    const getParameters = () => {
        return [
            {key: "month", value: month},
            {key: "year", value: year},
            {key: "type", value: options[type]},
        ];
    }

    const createOutput = (json) => {
        return <Json json={json}/>
    }

    const handleMonthChange = (value) => {
        setMonth(value);
    }

    const handleYearChange = (value) => {
        setYear(value);
    }

    const handleTypeChange = (value) => {
        setType(value);
    }

    return <Fragment>
        <Title>Parameters</Title>
        <TextBox title="Month" value={month} onChange={handleMonthChange}/>
        <TextBox title="Year" value={year} onChange={handleYearChange}/>
        <DropDown title="Type" value={type} onChange={handleTypeChange} options={options} />
    </Fragment>
});