import React, {useEffect, useState} from "react";
import useSnackbar from "../components/hooks/Snackbar";

export const apis = [
    {url: "http://localhost:5142", name: "Local"},
    {url: "https://testapi.jagtbasen.dk", name: "Test"},
    {url: "https://api.jagtbasen.dk", name: "Production"},
];

const AuthContext = React.createContext({});

export const AuthContextProvider = ({children}) => {

    const [loggedIn, setLoggedIn] = useState(false);
    const [backend, setBackend] = useState('');
    const [password, setPassword] = useState('');

    const snackbar = useSnackbar();

    /**
     * Comment in while developing
     */
    useEffect(() => {
        if (process.env.REACT_APP_SKIP_LOGIN == "true") {
            setBackend(0);
            setPassword(process.env.REACT_APP_PASSWORD);
            setLoggedIn(true);
        }
    }, []);

    const handleLogin = async (backend, password) => {
        // Make a call to the selected backend
        // verifying the password
        const response = await fetch(apis[backend].url + "/api/Dashboard/auth", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": password
            },
        });

        if (!response.ok) {
            snackbar.open("Login failed", "error");
            return;
        }

        snackbar.open("Login successful", "success");
        setLoggedIn(true);
        setBackend(backend);
        setPassword(password);
    }

    const handleLogout = () => {
        setBackend('');
        setPassword('');
        setLoggedIn(false);
        snackbar.open("Logged out", "success");
    }

    return <AuthContext.Provider
        value={{
            login: handleLogin,
            logout: handleLogout,
            api: apis[backend],
            password: password,
            loggedIn: loggedIn
        }}
    >
        {children}
    </AuthContext.Provider>
}

export const useAuth = () => React.useContext(AuthContext);