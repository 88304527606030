import React, {Fragment} from "react";
import {Button} from "@mui/material";
import Compressor from "compressorjs";
import {compressAccurately} from "image-conversion";
import useSnackbar from "../hooks/Snackbar";

const compressAndConvert = async (image, maxWidth, maxHeight, maxSize) => {
    // Scale the image to a max width/height in pixel
    image = await new Promise((resolve, reject) => {
        new Compressor(image, {
            quality: 1,
            maxHeight: maxHeight,
            maxWidth: maxWidth,
            convertSize: 0,
            convertTypes: "image/*",
            success: resolve,
            error: reject,
        });
    });

    // Make sure the image in no bigger than maxSize
    return await compressAccurately(image, {
        size: maxSize,
        accuracy: 0.95,
        type: "image/jpeg",
    });
};

const InputImage = ({
    maxWidth = 2000, // in pixels
    maxHeight = 2000, // in pixels
    maxInputSize = 100, // in MB
    maxOutputSize = 1000, // in KB
    callback = () => {}
}) => {
    const snackbar = useSnackbar();

    return <Fragment>
        {/* Hidden input HTML*/}
        <input
            id="file-upload"
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={async (event) => {
                let file = [...event.target.files][0];

                // Check if file is too big
                if (file.size > maxInputSize * 1097152) {
                    snackbar.open(`The file was too big. We have set a limit on ${maxInputSize}MB`, "error");
                    return;
                }

                // Compress and convert the image
                try {
                    file = await compressAndConvert(file, maxWidth, maxHeight, maxOutputSize);
                } catch (e) {
                    snackbar.open("Could not compress image", "error");
                    return;
                }

                // Return the final image
                await callback(file);
                event.target.value = null;
            }}
        />
        {/* Shown HTML */}
        <label htmlFor="file-upload">
            <Button
                variant="contained"
                color="primary"
                component="span"
                sx={{textTransform: "unset"}}
            >
                Choose Image
            </Button>
        </label>
    </Fragment>
};

export default InputImage;
