import React, {forwardRef, Fragment, useImperativeHandle, useState} from "react";
import {Title} from "../../../../form/Title";
import {useOutput} from "../../../../../contexts/OutputContext";
import {useAuth} from "../../../../../contexts/AuthContext";
import {TextBox} from "../../../../form/TextBox";
import {DateBox} from "../../../../form/DateBox";
import {Space} from "../../../../basic/Space";
import {Row} from "../../../../basic/Row";
import {DropDown} from "../../../../form/DropDown";
import useSnackbar from "../../../../hooks/Snackbar";
import {Text} from "../../../../form/Text";

export const EditSchedule = forwardRef((props, ref) => {
    const output = useOutput();
    const auth = useAuth();
    const snackbar = useSnackbar();

    const path = auth.api.url + "/api/banners/edit-schedule";
    const today = new Date();
    const dateString = today.toISOString().split('T')[0];
    const locations = [
        "D1",
        "M1",
        "D2",
        "M2",
        "D3",
        "M3"
    ];

    const [start, setStart] = useState(dateString);
    const [end, setEnd] = useState(dateString);
    const [location, setLocation] = useState("");
    const [comment, setComment] = useState("");
    const [image, setImage] = useState("");
    const [link, setLink] = useState("");

    useImperativeHandle(ref, () => {
        return {
            handleGo: handleGo
        }
    })

    const handleGo = async () => {
        if (location === "") {
            snackbar.open("Missing a location", "error");
            return false;
        }

        output.setLoading(true);
        output.setComponent(null);
        output.setPath(path);

        try {
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": auth.password
                },
                body: JSON.stringify({
                    "StartDate": start,
                    "EndDate": end,
                    "Location": locations[location],
                    "Comment": comment,
                    "Image": image,
                    "Link": link
                })
            };

            const response = await fetch(
                path,
                requestOptions
            );

            if (!response.ok) {
                snackbar.open("Something went wrong", "error");
                return false;
            }

            if (image === "") {
                snackbar.open("Banner(s) removed", "error");
            }

            output.setComponent(<Title>Success!</Title>)
            return false;
        } finally {
            output.setLoading(false);
        }
    }

    const onStartDateChange = (date) => {
        setStart(date);
    }

    const onEndDateChange = (date) => {
        setEnd(date);
    }

    const onLocationPick = (location) => {
        setLocation(location)
    }

    const onCommentChange = (comment) => {
        setComment(comment);
    }

    const onImageChange = (image) => {
        setImage(image);
    }

    const onLinkChange = (link) => {
        setLink(link);
    }

    return <Fragment>
        <Text><strong>Important:</strong> Note that changes are irreversible and cannot be undone once applied.</Text>
        <Space height="10px"/>
        <DropDown
            title="Location"
            value={location}
            onChange={onLocationPick}
            options={locations}
            sx={{maxWidth: "120px"}}
        />
        <Space height="20px"/>
        <Row>
            <DateBox label="Start Date" callback={onStartDateChange}/>
            <Space width="15px"/>
            <DateBox label="End Date" callback={onEndDateChange}/>
        </Row>
        <Space height="10px"/>
        <TextBox
            title="Comment"
            onChange={onCommentChange}
        />
        <TextBox
            title="Image"
            helperText="The URL of the image you want to display on the banner. If you don't have an image URL, you can use the 'Upload Image' endpoint to generate one. If no URL is provided all banners for all the dates provided will be removed for that location."
            onChange={onImageChange}
        />
        <TextBox
            title="Link"
            helperText="The URL where users will be redirected after clicking on the banner. Remember to include 'http://' or 'https://' at the beginning of the URL if redirecting to an external page, e.g. 'http://www.boozt.com'."
            onChange={onLinkChange}
        />
    </Fragment>
});