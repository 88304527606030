import React, {forwardRef, Fragment, useImperativeHandle, useState} from "react";
import {Title} from "../../../../form/Title";
import {DateBox} from "../../../../form/DateBox";
import {Space} from "../../../../basic/Space";
import {styled, Typography} from "@mui/material";
import {Row} from "../../../../basic/Row";

const Description = styled(Typography)(({theme}) => ({
    fontSize: "14px",
    color: theme.palette.text.secondary,
}));

export const ViewSchedule = forwardRef((props, ref) => {
    const today = new Date();
    const dateString = today.toISOString().split('T')[0];

    const [start, setStart] = useState(dateString);
    const [end, setEnd] = useState(dateString);

    useImperativeHandle(ref, () => {
        return {
            getPathname: () => "/banners/view-schedule",
            getParameters: getParameters,
            createOutput: createOutput,
            handleGo: () => {}
        }
    })

    const getParameters = () => {
        return [
            {key: "start", value: start},
            {key: "end", value: end}
        ];
    }

    const createOutput = (data) => {
        return (
            data.map((dateGroup, index) => (
                <Fragment key={"date-group-" + index}>
                    <Title>{dateGroup["Date"].split("T")[0]}</Title>
                    {dateGroup["Items"].map((item, itemIndex) => (
                        <Fragment key={"date-group-" + index + "-location-" + itemIndex}>
                            <Description>{"Location: " + item["Location"]}</Description>
                            <Description>{"Comment: " + item["Comment"]}</Description>
                            <Description>{"Clicks: " + item["Clicks"]}</Description>
                            <Description>{"Link: " + item["Link"]}</Description>
                            <Description>{"Image: " + item["Image"]}</Description>
                            <Space height={"5px"}/>
                            <img
                                src={item["Image"]}
                                alt="Location image"
                                width="100%"
                                style={{
                                    maxHeight: "300px",
                                    objectFit: "contain"
                                }}
                            />
                            <Space height={"5px"}/>
                        </Fragment>
                    ))}
                </Fragment>
            ))
        );
    }

    const onNewStartDate = (date) => {
        setStart(date);
    }

    const onNewEndDate = (date) => {
        setEnd(date);
    }

    return <Row>
        <DateBox label="Start Date" callback={onNewStartDate}/>
        <Space width="15px"/>
        <DateBox label="End Date" callback={onNewEndDate}/>
    </Row>
});