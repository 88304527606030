import {Box, Button, Checkbox, Modal, Typography} from "@mui/material";
import React, {forwardRef, Fragment, useImperativeHandle, useState} from "react";
import {Column} from "../../../../basic/Column";
import {Row} from "../../../../basic/Row";
import {Indent} from "../../../../basic/Indent";
import {Space} from "../../../../basic/Space";
import {useForm} from "react-hook-form";
import {Text} from "../../../../form/Text";
import {Loader} from "../../../../basic/Loader";
import {useAuth} from "../../../../../contexts/AuthContext";
import useSnackbar from "../../../../hooks/Snackbar";
import {useConfirmationDialog} from "../../../../dialogs/ConfirmationDialog";

const endpoint = "/posts/dash/review-posts";

const ReviewLine = ({title, children}) => {
    return <Typography>
        <span style={{fontWeight: "bold"}}>{title}: </span>
        <span>{children}</span>
    </Typography>
}

const ReviewReports = ({reports}) => {
    return <Fragment>
        {reports.map((report) => {
            return <Column key={`${report.Id}-${report.Time}`}>
                <ReviewLine title={"Report"}>
                    {report.Id}
                </ReviewLine>
                <Row>
                    <Indent/>
                    <Column>
                        <ReviewLine title={"Time"}>
                            {report.Time}
                        </ReviewLine>
                        <ReviewLine title={"Category ID"}>
                            {report.CategoryId}
                        </ReviewLine>
                        <ReviewLine title={"User ID"}>
                            {report.UserId}
                        </ReviewLine>
                        <ReviewLine title={"Message"}>
                            {report.Message}
                        </ReviewLine>
                    </Column>
                </Row>
            </Column>
        })}
    </Fragment>
}

const ReviewImages = ({images}) => {
    const [modalState, setModalState] = useState({
        open: false,
        image: "",
    });

    return <Box >
        <ReviewLine title="Images"/>

        {/* Align images */}
        {images.map((image, index) => {
            const splitUrl = image.split("/");

            return <img
                key={splitUrl[splitUrl.length - 1]}
                src={image}
                alt="Review image"
                style={{
                    width: "48%",
                    marginRight: index % 2 === 0 ? "1%" : "0px",
                    height: "300px",
                    maxHeight: "300px",
                    objectFit: "contain",
                    cursor: "pointer",
                    border: "1px solid grey",
                }}
                onClick={() => setModalState({
                    open: true,
                    image: image,
                })}
            />
        })}

        {/* If an image is pressed a modal with the image is shown */}
        <Modal
            open={modalState.open}
            onClose={() => setModalState({
                open: false,
                image: "",
            })}
        >
            <img
                src={modalState.image}
                alt="Review image"
                style={{
                    position: "absolute",
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    maxHeight: "calc(100vh - 20vw)",
                    maxWidth: "calc(100vw - 20vw)",
                    objectFit: "contain",
                }}
            />
        </Modal>
    </Box>
}

const ReviewItem = ({post, loading, register}) => {
    return <Row>
        <Checkbox
            {...register(post.PostId)}
            disabled={loading}
            sx={{height: "fit-content"}}
        />
        <Column>
            <Space height="10px"/>
            <ReviewLine title={"Title"}>
                {post.Heading}
            </ReviewLine>

            <ReviewImages images={post.Images}/>

            <ReviewReports reports={post.Reports}/>

            <ReviewLine title={"Category"}>
                {`${post.Category.CategoryName} > ${post.Category.SubcategoryName}`}
            </ReviewLine>
            <ReviewLine title={"Tier"}>
                {post.Tier}
            </ReviewLine>
            <ReviewLine title={"Body"}>
                {post.Body}
            </ReviewLine>
            <ReviewLine title={"Price"}>
                {post.Price}
            </ReviewLine>
            <ReviewLine title={"Creation Time"}>
                {post.CreationTime}
            </ReviewLine>
            <ReviewLine title={"Activation Time"}>
                {post.ActivationTime}
            </ReviewLine>
            <ReviewLine title={"Review Time"}>
                {post.ReviewTime}
            </ReviewLine>
            <ReviewLine title={"Username"}>
                {post.UserName}
            </ReviewLine>
            <ReviewLine title={"Email"}>
                {post.Email}
            </ReviewLine>
            <ReviewLine title={"Post ID"}>
                {post.PostId}
            </ReviewLine>
            <ReviewLine title={"User ID"}>
                {post.UserId}
            </ReviewLine>
        </Column>
    </Row>
}

const ReviewMenu = ({json}) => {
    const auth = useAuth();
    const snackbar = useSnackbar();
    const getConfirmation = useConfirmationDialog()
    const {register, handleSubmit} = useForm();

    const [loading, setLoading] = useState(false);
    const [posts, setPosts] = useState(json);

    const path = auth.api.url + "/api" + endpoint;

    const approvePosts = async (postIds) => {
        const response = await fetch(path, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": auth.password
            },
            body: JSON.stringify(postIds)
        });

        if (!response.ok) {
            console.log(response);
            snackbar.open("Failed to approve posts", "error");
            return;
        }

        snackbar.open(`${postIds.length} posts approved`, "success");
    }

    const fetchPosts = async () => {
        const response = await fetch(path, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": auth.password
            }
        });

        if (!response.ok) {
            console.log(response);
            snackbar.open("Failed to approve posts", "error");
            return;
        }

        const json = await response.json();
        setPosts(json);
    }

    const onApprove = async (event) => {
        const postIds = Object.keys(event).filter(key => event[key]);

        if (postIds.length === 0) {
            snackbar.open("No posts selected", "error");
            return;
        }

        const proceed = await getConfirmation({
            title: `Approve ${postIds.length} posts?`,
            message: "This will mark the posts as approved in the backend and in Algolia, making them visible on Jagtbasen.",
            confirmText: "Approve",
        })

        if (!proceed) return;

        setLoading(true)

        try {
            await approvePosts(postIds);
        } catch (e) {
            console.log(e);
            snackbar.open("Failed to approve posts", "error");
        }

        try {
            await fetchPosts();
        } catch (e) {
            console.log(e);
            snackbar.open("Failed to fetch posts", "error");
        }

        setLoading(false)
    }

    return <form onSubmit={handleSubmit(onApprove)}>
        {posts.map((post) => {
            return <Fragment key={post.PostId}>
                <ReviewItem
                    post={post}
                    loading={loading}
                    register={register}
                />
                <Space height="5px"/>
            </Fragment>
        })}

        <Space height="15px"/>

        {loading && <Loader/>}

        {/* Approve button */}
        {!loading && (
            <Button variant="contained" type="submit">
                Approve
            </Button>
        )}
    </form>
}

export const ReviewPosts = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => {
        return {
            getPathname: () => endpoint,
            getParameters: () => [],
            createOutput: createOutput,
            handleGo: () => {}
        }
    })

    const createOutput = (json) => {
        return <ReviewMenu json={json}/>
    }

    return <Fragment>
        <Text>
            Provide a menu to review all posts up for review.
        </Text>
    </Fragment>
});