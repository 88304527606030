import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {createTheme, ThemeProvider} from "@mui/material";
import {SnackbarProvider} from "./components/hooks/Snackbar";
import {AuthContextProvider} from "./contexts/AuthContext";
import {OutputContextProvider} from "./contexts/OutputContext";
import {ConfirmationDialogProvider} from "./components/dialogs/ConfirmationDialog";


const theme = createTheme({
    palette: {
        primary: {
            main: "#5e1022",
        }
    }
});

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <SnackbarProvider>
                <AuthContextProvider>
                    <OutputContextProvider>
                        <ConfirmationDialogProvider>
                            <App />
                        </ConfirmationDialogProvider>
                    </OutputContextProvider>
                </AuthContextProvider>
            </SnackbarProvider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
)
