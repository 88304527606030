import {useOutput} from "../../../contexts/OutputContext";
import React, {Fragment} from "react";
import {Typography} from "@mui/material";
import {Space} from "../../basic/Space";
import CircularProgress from "@mui/material/CircularProgress";

export const Output = () => {
    const output = useOutput();

    const Component = output.Component;

    if (output.loading) {
        return <CircularProgress/>
    }

    return <Fragment>
        {Component && (
            <Fragment>
                <Typography>
                    <span style={{ fontWeight: "bold" }}>
                        Url
                    </span>: {output.path}
                </Typography>
                <Typography>
                    <span style={{ fontWeight: "bold" }}>
                        Response
                    </span> - {new Date().toLocaleString()}
                </Typography>
                <Space height="10px"/>
                {Component}
            </Fragment>
        )}
    </Fragment>
}