import ReactJson from "react-json-view";
import {Button} from "@mui/material";
import React, {Fragment} from "react";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

export const Json = ({json}) => {
    const [collapsed, setCollapsed] = React.useState(false);

    const handleCollapse = () => {
        setCollapsed(prev => !prev);
    }

    return <Fragment>
        <Button
            variant="contained"
            onClick={handleCollapse}
            sx={{
                width: "40px",
                minWidth: "40px",
                padding: "0",
                margin: "0 0 15px 0",
            }}
        >
            {collapsed
                ? <ArrowRightIcon/>
                : <ArrowDropDownIcon/>
            }
        </Button>
        <ReactJson
            src={json}
            collapsed={collapsed}
            collapseStringsAfterLength={50}
            enableClipboard={false}
        />
    </Fragment>
}