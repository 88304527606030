import React, {forwardRef, Fragment, useImperativeHandle, useState} from "react";
import {Json} from "../../../basic/Json";
import {TextBox} from "../../../form/TextBox";
import {Title} from "../../../form/Title";

export const Feedbacks = forwardRef((props, ref) => {
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');

    useImperativeHandle(ref, () => {
        return {
            getPathname: () => "/dashboard/feedback",
            getParameters: getParameters,
            createOutput: createOutput,
            handleGo: () => {}
        }
    })

    const getParameters = () => {
        return [
            {key: "month", value: month},
            {key: "year", value: year}
        ];
    }

    const createOutput = (json) => {
        return <Json json={json}/>
    }

    const handleMonthChange = (value) => {
        setMonth(value);
    }

    const handleYearChange = (value) => {
        setYear(value);
    }

    return <Fragment>
        <Title>Parameters</Title>
        <TextBox title="Month" value={month} onChange={handleMonthChange}/>
        <TextBox title="Year" value={year} onChange={handleYearChange}/>
    </Fragment>
});