import React, {forwardRef, Fragment, useImperativeHandle} from "react";
import {
    DailyDeletionCounts,
    DeleteReasonCounts,
    DeletionTimeAnalysis,
    PostDeletionRatioToTotalPosts
} from "../../../../plots/DeletedPosts";
import {Divider, Stack} from "@mui/material";

export const DeletedPosts = forwardRef((props, ref) => {

    useImperativeHandle(ref, () => {
        return {
            getPathname: () => "/dashboard/stats/deletedposts",
            getParameters: () => [],
            createOutput: createOutput,
            handleGo: () => {}
        }
    })

    const createOutput = (json) => {
        return <Stack spacing={3}  divider={<Divider orientation="horizontal" flexItem />}>
            <DeleteReasonCounts data={json}/>
            <DailyDeletionCounts data={json}/>
            <PostDeletionRatioToTotalPosts data={json}/>
            <DeletionTimeAnalysis data={json}/>
        </Stack>
    }

    return <Fragment>
    </Fragment>
});