import React, {forwardRef, Fragment, useImperativeHandle} from "react";
import {useConfirmationDialog} from "../../../../dialogs/ConfirmationDialog";
import {Typography} from "@mui/material";
import {Text} from "../../../../form/Text";

export const SyncAlgolia = forwardRef((props, ref) => {
    const getConfirmation = useConfirmationDialog();

    useImperativeHandle(ref, () => {
        return {
            getPathname: () => "/posts/sync-algolia",
            getParameters: () => [],
            createOutput: createOutput,
            handleGo: handleGo
        }
    })

    const createOutput = (_) => {
        return <Typography variant="h5">Done</Typography>
    }

    const handleGo = async () => {
        return await getConfirmation({
            title: "BEWARE",
            message: "Depending on how many records the database contains, this can be a rather large job. Also, if people are updating the database concurrently, this could potentially result in race conditions.",
            confirmText: "Synchronize",
        })
    }

    return <Fragment>
        <Text>
            Deletes all existing records from Algolia before reuploading them from the database, that way making sure the two are in sync. Inactive, expired, deleted, unpaid or unaccepted posts are not uploaded.
        </Text>
    </Fragment>
})