import React, {forwardRef, Fragment, useImperativeHandle, useState} from "react";
import {LineChart} from "../../../../plots/LineChart";
import {Title} from "../../../../form/Title";
import {TextBox} from "../../../../form/TextBox";
import {Text} from "../../../../form/Text";
import {Typography} from "@mui/material";

export const UsersStats = forwardRef((props, ref) => {
    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');

    useImperativeHandle(ref, () => {
        return {
            getPathname: () => "/dashboard/stats/users",
            getParameters: getParameters,
            createOutput: createOutput,
            handleGo: () => {}
        }
    })

    const getParameters = () => {
        return [
            {key: "start", value: start},
            {key: "end", value: end}
        ];
    }

    const createOutput = (json) => {
        const change = json[json.length - 1] - json[0]
        const changePerDay = (change / json.length).toFixed(2)

        return <Fragment>
            <LineChart data={json}/>
            <Typography>Change: {change}</Typography>
            <Typography>Change/Day: {changePerDay}</Typography>
        </Fragment>
    }

    const handleStartChange = (value) => {
        setStart(value);
    }

    const handleEndChange = (value) => {
        setEnd(value);
    }

    return <Fragment>
        <Title>Parameters</Title>
        <Text>You can optionally set a start and end time for the plot using Unix timestamps. Regardless of the exact time provided within a day, the entire day will be included in the data. If you don't specify times, the plot will automatically cover the past 31 days, ending with today.</Text>
        <TextBox title="Start" value={start} onChange={handleStartChange}/>
        <TextBox title="End" value={end} onChange={handleEndChange}/>
    </Fragment>
});