import React, {forwardRef, Fragment, useImperativeHandle, useState} from "react";
import {BarChart} from "../../../../plots/BarChart";

export const VisitsStats = forwardRef((props, ref) => {
    const [id, setId] = useState('');

    useImperativeHandle(ref, () => {
        return {
            getPathname: () => "/dashboard/stats/visitors",
            getParameters: () => [],
            createOutput: createOutput,
            handleGo: () => {}
        }
    })

    const createOutput = (json) => {
        return <BarChart data={json}/>
    }

    return <Fragment>
    </Fragment>
});