import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import {Dashboard} from "./components/dashboard/Dashboard";
import {Header} from "./components/Header";
import {Auth} from "./components/Auth";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    BarElement,
    ArcElement,
    Legend
} from 'chart.js';

// For plotting
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    BarElement,
    ArcElement,
    Legend
);

function App() {
  return (
      <Header>
          <Auth>
              <Router>
                  <Routes>
                      <Route path = "/" element={<Dashboard/>}/>
                  </Routes>
              </Router>
          </Auth>
      </Header>
  );
}

export default App;
