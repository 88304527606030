import {Fragment} from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {Column} from "../basic/Column";
import {Row} from "../basic/Row";
import {Box, Typography} from "@mui/material";
import {Space} from "../basic/Space";
import {useAuth} from "../../contexts/AuthContext";
import {Divider} from "../basic/Divider";
import {Input} from "./input/Input";
import {Output} from "./output/Output";

export const Dashboard = () => {
    const auth = useAuth();

    return <Column>
        {/* Log out */}
        <Row
            sx={{
                alignItems: "center",
                padding: "10px 5px",
                cursor: "pointer"
            }}
            onClick={auth.logout}
        >
            <ArrowBackIcon/>
            <Space width="5px"/>
            <Typography>Log out</Typography>
        </Row>
        <Space height="50px"/>

        {/* Body */}
        <Row sx={{
            width: "100%",
        }}>
            <Column sx={{width: "100%", alignItems: "end"}}>
                <Box sx={{maxWidth: "500px"}}>
                    <Input/>
                </Box>
            </Column>

            <Space width="20px"/>
            <Divider vertical={true}/>
            <Space width="20px"/>

            <Column sx={{width: "100%"}}>
                <Box sx={{maxWidth: "500px"}}>
                    <Output/>
                </Box>
            </Column>
        </Row>
        <Space height="50px"/>
    </Column>
}